<template>
  <b-card no-body class="w-100">
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">

        <!-- Sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="1"
          label-for="sortBySelect"
          label-class="mt-50 mr-50 pl-0"
          class="mb-0 text-nowrap"
          style="min-width: 20rem"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <!-- ./Sorting  -->

        <!-- Search -->
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          label-class="mt-50"
          class="mb-0"
          style="width: 30rem"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder=""
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!-- ./Search -->
      </div>
    </b-card-body>

    <b-table
      responsive
      striped
      hover
      show-empty
      class="position-relative"
      :items="behaviourTrees"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      :per-page="perPage"
      :current-page="currentPage"
      @row-contextmenu="item => tempRowObject = item"
      @contextmenu.native.prevent="$refs.menu.open"
      @row-dblclicked="item => $emit('openBehaviourTreeEdit', [item, true])"
    >
      <template #cell(title)="{ item }">
        <div class="d-inline-flex">
          <span>{{ item.title }}</span>
          <b-badge v-if="item.metadata != null && item.metadata.default === true" variant="primary" class="ml-50">
            Default
          </b-badge>
        </div>
      </template>
      <template #cell(description)="{ item }">
        <span class="v-html-edit">{{ item.description }}</span>
      </template>
      <template #cell(nodes)="{ item }">
        <div v-if="item.metadata != null" class="d-flex flex-row justify-content-center">
          <b-badge :variant="item.metadata.behaviourNodes > 0 ? 'info' : 'secondary'">
            {{ item.metadata.behaviourNodes }}
          </b-badge>
        </div>
      </template>
      <template #cell(updated)="{ item }">
        <span v-b-tooltip.hover.left.v-info="formatUpdatedTooltip(item)">
          {{ item.updated | diffForHumans }}
        </span>
      </template>
      <template #cell(status)="{ item }">
        <div v-if="item.status != null" class="d-flex flex-row justify-content-center">
          <b-badge :variant="determineVariant(item.status)">
            {{ item.status }}
          </b-badge>
        </div>
      </template>
      <template #cell(tag)="{ item }">
        <div v-if="item.tag != null && item.tag !== ''" class="d-flex flex-row justify-content-center">
          <b-badge variant="dark">
            {{ item.tag }}
          </b-badge>
        </div>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-inline-flex">
          <!--<b-button-->
          <!--  v-if="$store.state.auth.email.endsWith('@kompozition.com')"-->
          <!--  v-b-tooltip.hover.top="'Viewer'"-->
          <!--  variant="flat-primary"-->
          <!--  class="py-0 btn-icon"-->
          <!--  :disabled="loadingBehaviourTreeId === item.id || loadingBehaviourTreeId !== ''"-->
          <!--  @click="$emit('openBehaviourTreeView', item)"-->
          <!--&gt;-->
          <!--  <b-spinner-->
          <!--    v-if="loadingBehaviourTreeId === item.id"-->
          <!--    small-->
          <!--    variant="primary"-->
          <!--    type="grow"-->
          <!--    class="mr-50"-->
          <!--  />-->
          <!--  <feather-icon-->
          <!--    v-else-->
          <!--    icon="EyeIcon"-->
          <!--    size="16"-->
          <!--    class="align-text-top"-->
          <!--  />-->
          <!--</b-button>-->
          <b-button
            v-if="$store.state.auth.roles && $store.state.auth.roles.includes('administrator')"
            v-b-tooltip.hover.top.noninteractive="'Open in legacy mode'"
            variant="flat-secondary"
            class="py-0 btn-icon"
            :disabled="loadingBehaviourTreeId === item.id || loadingBehaviourTreeId !== ''"
            @click="$emit('openBehaviourTreeEdit', item)"
          >
            <b-spinner
              v-if="loadingBehaviourTreeId === item.id"
              small
              variant="primary"
              type="grow"
              class="mr-50"
            />
            <feather-icon
              v-else
              icon="EditIcon"
              size="16"
              class="align-text-top"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top.noninteractive="'Open Behaviour Tree'"
            variant="flat-primary"
            class="py-0 btn-icon"
            :disabled="loadingBehaviourTreeId === item.id || loadingBehaviourTreeId !== ''"
            @click="$emit('openBehaviourTreeEdit', [item, true])"
          >
            <b-spinner
              v-if="loadingBehaviourTreeId === item.id"
              small
              variant="primary"
              type="grow"
              class="mr-50"
            />
            <feather-icon
              v-else
              icon="EditIcon"
              size="16"
              class="align-text-top"
            />
          </b-button>
        </div>
      </template>
    </b-table>

    <vue-context ref="menu" class="text-left">
          <!--<li>-->
          <!--  <b-link-->
          <!--    :disabled="loadingBehaviourTreeId === tempRowObject.id || loadingBehaviourTreeId !== ''"-->
          <!--    @click="$emit('openBehaviourTreeView', tempRowObject)"-->
          <!--  >-->
          <!--    <feather-icon-->
          <!--      icon="EyeIcon"-->
          <!--      class="mr-75 font-small-3 text-primary"-->
          <!--    />-->
          <!--    <span class="text-primary">Viewer</span>-->
          <!--  </b-link>-->
          <!--</li>-->
          <li>
            <b-link
              :disabled="loadingBehaviourTreeId === tempRowObject.id || loadingBehaviourTreeId !== ''"
              @click="$emit('openBehaviourTreeEdit', [tempRowObject, true])"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-75 font-small-3 text-primary"
              />
              <span class="text-primary">Open</span>
            </b-link>
          </li>
          <hr>
          <li>
            <b-link @click="$emit('showModalUpdate', tempRowObject)">
              <feather-icon icon="Edit3Icon" class="mr-25 mb-25" />
              Properties
            </b-link>
          </li>
          <li>
            <b-link @click="$emit('showModalCopy', tempRowObject)">
              <feather-icon icon="CopyIcon" class="mr-25 mb-25" />
              Copy
            </b-link>
          </li>
          <li>
            <b-link @click="$emit('showModalDelete', tempRowObject)">
              <feather-icon icon="TrashIcon" class="mr-25 mb-25 text-danger" />
              Delete
            </b-link>
          </li>
        </vue-context>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- Page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-class="mt-50"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>
      <!-- ./Page length -->

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
      <!-- ./Pagination -->
    </b-card-body>
  </b-card>
</template>

<script>
import getEnv from '@/utils/env'
import { BPagination, BTable, VBTooltip } from 'bootstrap-vue'
import VueContext from 'vue-context'

export default {
  name: 'BehaviourTreeTableView',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    BPagination,
    VueContext,
  },
  props: {
    behaviourTrees: {
      required: true,
      type: Array,
    },
    loadingBehaviourTreeId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name', label: 'Name', sortable: true, tdClass: 'text-nowrap',
        },
        {
          key: 'tag', label: 'Tag', sortable: true,
        },
        {
          key: 'status', label: 'Status', sortable: true,
        },
        {
          key: 'nodes', label: 'Nodes', sortable: false,
        },
        {
          key: 'description', label: 'Description', sortable: true,
        },
        {
          key: 'actions', label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      perPage: 20,
      pageOptions: [10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'name',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tempRowObject: {},
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    behaviourTrees(newVal) {
      this.totalRows = newVal.length
    },
  },
  methods: {
    getEnv,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatUpdatedTooltip(item) {
      return `Last updated at ${item.updated}`
    },
    determineVariant(statusType) {
      if (statusType === 'Published') return 'success'
      if (statusType === 'Review') return 'warning'
      return 'secondary'
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-context.scss';

.v-html-edit {
  font-size: 0.9rem;
  p {
    margin: 0;
  }
}
</style>
